import Head from "next/head";

export default function Custom404() {
  return (
    <div className="container d-flex flex-grow-1 d-flex justify-content-center align-items-center">
      <Head>
        <title>{process.env.appName} : Page not found</title>
      </Head>
      <main className="px-3">
        <h1 className="h2">Page Not Found</h1>
      </main>
    </div>
  );
}
